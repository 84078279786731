import React from 'react'

const MissionStatement = () => (
  <div className="my-5 py-20 px-4 bg-white text-center">
    <div className="container mx-auto px-5">
      <h1 className="text-4xl tracking-tight text-open-blue mt-0">
        We Love To See You Smile!
      </h1>
      <p className="text-lg">
        We offer cutting-edge technology, comprehensive services, and
        experienced staff combine to give you amazingly effective dental care.
      </p>
    </div>
  </div>
)

export default MissionStatement
