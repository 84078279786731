import React from 'react'
import IoniconSVG from './IoniconSVG'

const Document = props => (
  <IoniconSVG {...props}>
    <title>document</title>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M288 48H136c-22.092 0-40 17.908-40 40v336c0 22.092 17.908 40 40 40h240c22.092 0 40-17.908 40-40V176L288 48zm-16 144V80l112 112H272z"
    />
  </IoniconSVG>
)

export default Document
