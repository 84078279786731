import React from 'react'
import Link from 'gatsby-link'
import jungImg from 'static/jung-thumb.png'
import sungImg from 'static/sung-thumb.png'

const DentistsSection = () => (
  <div className="my-5 py-20 px-4 bg-lighterGrey text-center">
    <div className="container mx-auto px-5">
      <h1 className="text-4xl tracking-tight text-open-blue mt-0">
        Meet Our Team
      </h1>
      <div className="flex sm:flex-row flex-col my-4">
        <div className="p-4 bg-white rounded shadow-lg mx-1 flex-1">
          <img
            className="w-32 h-32 rounded-full mx-auto mb-4"
            src={sungImg}
            alt="Sung Yeon Ji, DDS"
          />
          <p className="text-lg mb-4">
            DDS Dr. Sung Yeon Ji, DDS (DR. SUNG), received her DDS from New York
            University College of Dentistry in 2013.
          </p>
          <Link className="text-lg underline" to="/about_us#sung">
            Learn more about Dr. Sung
          </Link>
        </div>
        <div className="p-4 bg-white rounded shadow-lg mx-1 flex-1">
          <img
            className="w-32 h-32 rounded-full mx-auto mb-4"
            src={jungImg}
            alt="Jung Hun Ji, DDS"
          />
          <p className="text-lg mb-4">
            Dr. Jung Hun Ji (DR. JI) graduated from Loma Linda University with
            Doctorate of Dental Surgery in 2014.
          </p>
          <Link className="text-lg underline" to="/about_us#jung">
            Learn more about Dr. Ji
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default DentistsSection
