import React from 'react'
import IoniconSVG from './IoniconSVG'

const Heart = props => (
  <IoniconSVG {...props}>
    <title>heart</title>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M256 448l-30.164-27.211C118.718 322.442 48 258.61 48 179.095 48 114.221 97.918 64 162.4 64c36.399 0 70.717 16.742 93.6 43.947C278.882 80.742 313.199 64 349.6 64 414.082 64 464 114.221 464 179.095c0 79.516-70.719 143.348-177.836 241.694L256 448z"
    />
  </IoniconSVG>
)

export default Heart
