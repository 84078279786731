import React, { useEffect, useState } from 'react'
import MUIStarIcon from '@material-ui/icons/Star'
import poweredByGoog from 'static/powered_by_google_on_white_hdpi.png'

const Rating = ({ rating = 1 }) => (
  <div>
    {new Array(rating).fill().map(_rating => (
      <MUIStarIcon className="text-orange w-10" />
    ))}
  </div>
)

let map

const getPlaceDetail = callback => {
  const request = {
    placeId: 'ChIJsT7VSLpwlVQRGkmIo0GxaY8',
    fields: ['review']
  }
  const service = new google.maps.places.PlacesService(map)

  service.getDetails(request, callback)
}

const GoogleReviews = () => {
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    map = new google.maps.Map(document.createElement('div'))
    getPlaceDetail(data => data && setReviews(data.reviews))
  }, [])

  return reviews.length ? (
    <div className="py-20 px-4 bg-lighterCyan">
      <div className="container mx-auto px-5">
        <h1 className="text-4xl tracking-tight text-open-blue mt-0 text-center">
          Our Reviews
        </h1>

        <div className="flex flex-col md:flex-row justify-between">
          {reviews.slice(0, 2).map((review, idx) => (
            <div
              key={idx}
              className="flex-1 flex flex-col justify-between bg-white rounded-lg shadow-lg p-5 m-1"
            >
              <p className="text-base leading-snug mb-5">{review.text}</p>
              <div className="flex justify-between items-center">
                <Rating rating={review.rating} />
                <p className="text-sm">{review.relative_time_description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col md:flex-row justify-between">
          {reviews.slice(2, 4).map((review, idx) => (
            <div
              key={idx}
              className="flex-1 flex flex-col justify-between bg-white rounded-lg shadow-lg p-5 m-1"
            >
              <p className="text-base leading-snug mb-5">{review.text}</p>
              <div className="flex justify-between items-center">
                <Rating rating={review.rating} />
                <p className="text-sm">{review.relative_time_description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-4">
          <img
            className="mr-1"
            style={{ height: 20 }}
            src={poweredByGoog}
            alt="Powered By Google"
          />
        </div>
      </div>
    </div>
  ) : null
}

export default GoogleReviews
