import React, { useState } from 'react'
import InstagramEmbed from 'react-instagram-embed'

const InstagramPosts = () => {
  const [posts, setPosts] = useState([
    'p/B6yl73fpxe6',
    'p/B6dqHhnJc-A',
    'p/B5t3FHNpjaY'
  ])

  return (
    <div className="py-16 px-4 bg-white text-center">
      <h1 className="text-4xl tracking-tight text-open-blue mt-0">
        Us on Instagram
      </h1>
      <div className="flex py-4 justify-center">
        {posts.map((post, idx) => (
          <div
            key={idx}
            className={`flex-1 max-w-xs ${
              idx !== 0 ? 'hidden' : ''
            } lg:block lg:mx-4`}
          >
            <InstagramEmbed
              url={`https://instagr.am/${post}`}
              hideCaption={false}
              containerTagName="div"
              protocol=""
              injectScript
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default InstagramPosts
