import React from 'react'
import IoniconSVG from './IoniconSVG'

const Navigate = props => (
  <IoniconSVG {...props}>
    <title>navigate</title>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M256 64L96 433.062 110.938 448 256 384l145.062 64L416 433.062z"
    />
  </IoniconSVG>
)

export default Navigate
