import React, { useEffect, useState, useRef } from 'react'
import firstImg from 'static/gallery/1.jpg'
import secondImg from 'static/gallery/2.jpg'
import thirdImg from 'static/gallery/3.jpg'
import fourthImg from 'static/gallery/4.jpg'
import fifthImg from 'static/gallery/5.jpg'

const imageContainerClassName = `absolute top-0 left-0 overflow-hidden w-full h-full transition-opacity transition-500`

const Slide = ({ children, visible }) => (
  <div
    className={`${imageContainerClassName} ${
      visible ? 'opacity-100' : 'opacity-0'
    }`}
  >
    {children}
  </div>
)

const imgClassName = 'w-full h-full bg-cover bg-no-repeat bg-center'

const Img = ({ src }) => (
  <div
    className={imgClassName}
    style={{
      backgroundImage: `url('${src}')`
    }}
  />
)

const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

const Gallery = () => {
  const [current, setCurrent] = useState(1)

  useInterval(() => setCurrent(current === 5 ? 1 : current + 1), 3000)

  return (
    <div>
      <Slide visible={current === 1}>
        <Img src={firstImg} alt="Oregon City Smiles Dental Office #1" />
      </Slide>
      <Slide visible={current === 2}>
        <Img src={secondImg} alt="Oregon City Smiles Dental Office #2" />
      </Slide>
      <Slide visible={current === 3}>
        <Img src={thirdImg} alt="Oregon City Smiles Dental Office #3" />
      </Slide>
      <Slide visible={current === 4}>
        <Img src={fourthImg} alt="Oregon City Smiles Dental Office #4" />
      </Slide>
      <Slide visible={current === 5}>
        <Img src={fifthImg} alt="Oregon City Smiles Dental Office #5" />
      </Slide>
    </div>
  )
}

export default Gallery
