import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Gallery from './index/Gallery'
import DentistsSection from './index/DentistsSection'
import TreatmentsSection from './index/TreatmentsSection'
import FourKeyPoints from './index/FourKeyPoints'
import MissionStatement from './index/MissionStatement'
import Memberships from './index/Memberships'
import InstagramPosts from './index/InstagramPosts'
import GoogleReviews from './index/GoogleReviews'
// import CovidDialog from './index/CovidDialog'
// import PromoDialog from './index/PromoDialog'

const Index = () => (
  <Layout>
    <div>
      <Helmet title="Welcome to Oregon City Smiles - A general family friendly dental pratice" />

      <div className="bg-blue relative text-center">
        <div
          className="flex relative items-end border-box"
          style={{ minHeight: 500 }}
        >
          <div className="flex flex-col w-full h-full justify-center mb-12 z-10">
            <h1 className="text-4xl text-offWhite uppercase m-8 text-shadow-lg">
              Welcome to Oregon City Smiles
            </h1>
            <p className="px-20 text-lg text-offWhite text-shadow-lg leading-snug">
              Conveniently located on Highway 213 in Oregon City, Oregon, our
              office provides modern dentistry in a unique, caring environment.
            </p>
          </div>
        </div>
        <Gallery />
      </div>

      <MissionStatement />
      <FourKeyPoints />
      <Memberships />
      <TreatmentsSection />
      <DentistsSection />
      <InstagramPosts />
      <GoogleReviews />
    </div>
  </Layout>
)

export default Index
