import React from 'react'
import Link from 'gatsby-link'
import CalendarIcon from 'components/icons/Calendar'
import DocumentIcon from 'components/icons/Document'
import NavigateIcon from 'components/icons/Navigate'
import HeartIcon from 'components/icons/Heart'

const FourKeyPoints = () => (
  <div className="py-20 px-4 bg-lighterCyan text-center">
    <div className="container mx-auto px-5">
      <h5 className="text-left text-lg text-open-blue font-bold uppercase mt-0 mb-12">
        Services that delight and deliver
      </h5>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 flex flex-col lg:flex-row items-center lg:items-start mb-8 mr-2">
          <div className="flex h-32 w-32 bg-white rounded-full items-center justify-center mr-6 mb-4">
            <CalendarIcon fill="mainBlue" size="4rem" />
          </div>
          <div className="flex-1 lg:text-left sm:text-center">
            <h4 className="text-4xl text-open-blue">Convenient Appointments</h4>
            <p className="text-base text-hairline mt-8 mb-4">
              Walk-in & next-day appointments welcome! We’re your emergency
              dentists. Our team is always here for you!
            </p>
            <Link className="text-lg underline" to="/appointments">
              Schedule an appointment
            </Link>
          </div>
        </div>

        <div className="flex-1 flex flex-col lg:flex-row items-center lg:items-start mb-8 mr-2">
          <div className="flex h-32 w-32 bg-white rounded-full items-center justify-center mr-6 mb-4">
            <HeartIcon color="mainBlue" fill="mainBlue" size="4rem" />
          </div>
          <div className="flex-1 lg:text-left sm:text-center">
            <h4 className="text-4xl text-open-blue">Family Services</h4>
            <p className="text-base text-hairline mt-8 mb-4">
              We take pride in our ability to serve all ages. Treatment plans
              are tailored accordingly. Our team enjoys caring for even your
              youngest family member.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 flex flex-col lg:flex-row items-center lg:items-start mb-8 mr-2">
          <div className="flex h-32 w-32 bg-white rounded-full items-center justify-center mr-6 mb-4">
            <DocumentIcon color="mainBlue" fill="mainBlue" size="4rem" />
          </div>
          <div className="flex-1 lg:text-left sm:text-center">
            <h4 className="text-4xl text-open-blue">Easy Insurance</h4>
            <p className="text-base text-hairline mt-8 mb-4">
              No need to wait for your insurance reimbursement! We send your
              insurance claim for you.
            </p>
          </div>
        </div>

        <div className="flex-1 flex flex-col lg:flex-row items-center lg:items-start mb-8 mr-2">
          <div className="flex h-32 w-32 bg-white rounded-full items-center justify-center mr-6 mb-4">
            <NavigateIcon color="mainBlue" fill="mainBlue" size="4rem" />
          </div>
          <div className="flex-1 lg:text-left sm:text-center">
            <h4 className="text-4xl text-open-blue">Great Location</h4>
            <p className="text-base text-hairline mt-8 mb-4">
              Conveniently located on Highway 213 in Oregon City.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default FourKeyPoints
