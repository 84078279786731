import React from 'react'

const chartClassName =
  'flex-1 p-4 m-1 text-center list-none bg-white border border-solid border-herokuGray rounded-lg hover:border-herokuBlue transition-border transition-100'

const chartHeaderClassName = 'text-heroku text-lg font-bold mb-8'

const chartListClassName =
  'text-heroku text-xs font-hairline uppercase p-2 border-b border-herokuGray'

const chartListBorderTopClassName = `${chartListClassName} border-t`

const chartPriceClassName = `mt-4 p-2 text-heroku text-sm border border-heroku rounded-sm`

const Memberships = () => (
  <div className="py-20 px-4 bg-lighterGrey text-center">
    <div className="container mx-auto px-5">
      <h1 className="text-4xl tracking-tight text-open-blue mt-0">
        Oregon City Smiles Membership Programs
      </h1>
      <p className="text-base text-center">
        We want to help you with your continuing care. Oregon City Smiles is
        pleased to offer a dental membership plan as an alternative to dental
        insurance for our uninsured patients.
      </p>
      <div className="flex flex-col lg:flex-row mt-6 mb-12">
        <ul className={chartClassName}>
          <li className={chartHeaderClassName}>Regular Adult Membership</li>
          <li className={chartListBorderTopClassName}>2 Regular Cleanings</li>
          <li className={chartListClassName}>2 Periodic Exams</li>
          <li className={chartListClassName}>2 Limited Exams</li>
          <li className={chartListClassName}>
            Full mouth x-rays, annual check-up x-rays
          </li>
          <li className={chartListClassName}>Oral Canceer Screening</li>
          <li className={chartListClassName}>2 Fluoride Treatments</li>
          <li className={chartListClassName}>20% Savings on ALL Treatments</li>
          <li className={chartPriceClassName}>$400.00</li>
        </ul>
        <ul className={chartClassName}>
          <li className={chartHeaderClassName}>Adult Perio Membership</li>
          <li className={chartListBorderTopClassName}>
            4 Periodontal Cleanings
          </li>
          <li className={chartListClassName}>2 Periodic Exams</li>
          <li className={chartListClassName}>2 Limited Exams</li>
          <li className={chartListClassName}>
            Full mouth x-rays, annual check-up x-rays
          </li>
          <li className={chartListClassName}>Oral Canceer Screening</li>
          <li className={chartListClassName}>2 Fluoride Treatments</li>
          <li className={chartListClassName}>20% Savings on ALL Treatments</li>
          <li className={chartPriceClassName}>$750.00</li>
        </ul>
        <ul className={chartClassName}>
          <li className={chartHeaderClassName}>
            Child Membership - 13 and under
          </li>
          <li className={chartListBorderTopClassName}>2 Cleanings</li>
          <li className={chartListClassName}>2 Periodic Exams</li>
          <li className={chartListClassName}>2 Limited Exams</li>
          <li className={chartListClassName}>
            Full mouth x-rays, annual check-up x-rays
          </li>
          <li className={chartListClassName}>Oral Canceer Screening</li>
          <li className={chartListClassName}>2 Fluoride Treatments</li>
          <li className={chartListClassName}>20% Savings on ALL Treatments</li>
          <li className={chartPriceClassName}>$300.00</li>
        </ul>
      </div>
    </div>
  </div>
)

export default Memberships
